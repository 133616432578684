import FooterScreen from "../../_common/FooterScreen/FooterScreen";
import HeaderScreen from "../../_common/Header/HeaderScreen";
import "./Home.css";
import {
  banner,
  banner1,
  icon1,
  icon2,
  icon3,
  icon4,
  brown,
  alphabet,
  arrow,
  alpha2,
  display1,
  display2,
  display3,
  white_ring,
  gold_ring,
  bracelet,
  neck,
  earings,
  necklace,
  rings,
  bang,
  loading,
} from "../../../assets/images";
import useHome from "./useHome";
import Slider from "react-slick";
import AboutUs from "../aboutus/AboutUs";

const Home = () => {
  const {
    menudata,
    image_url,
    getNewId,
    getMenuId,
    wishListCount,
    loader,
    getGoldDetails,
  } = useHome();

  var catregorySlider = {
    dots: false,
    arrows: false,
    infinite: false,
    autoplay: false,
    autoplaySpeed: 2500,
    speed: 1500,
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
    ],
  };

  var catregorySlider1 = {
    dots: false,
    arrows: false,
    infinite: false,
    autoplay: false,
    autoplaySpeed: 2500,
    speed: 1500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
    ],
  };
  //render header
  const renderHeaderBar = () => {
    return (
      <div>
        {/* {!loader && 
          <img src={loading} alt="prakash" />
        } */}
        <HeaderScreen count={wishListCount} />
      </div>
    );
  };

  //render banner section
  const renderBanner = () => {
    return (
      <div className="banner_container flt">
        {/* <Slider autoplaySpeed={5000} speed={1500} infinite={true} arrows={false} dots={false} autoplay={true} slidesToShow={1} slidesToScroll={1} className="mobile_hide flt" >
          {menudata &&
            menudata?.banners &&
            menudata?.banners !== null
            ? menudata?.banners.map((banner_list, index) => (

              <div key={index}>
                <a href={banner_list?.link}>
                  < img
                    src={image_url + banner_list?.image}
                    alt="no_image"
                    key={banner_list?._id}
                  />
                </a>
              </div>
            ))
            : null
          }
        </Slider> */}
        <img src={banner} alt="no_image" />
      </div>
    );
  };
  //render gold section
  const renderGold = () => {
    return (
      <div className="gold_main flt">
        <div className="container-main">
          <div className="gold_heading flt">
            <span>Gold Collection</span>
            <div className="gold_silver flt">
              Such a splendorous soul you are to be blessed with the majestic
              grandeur of gold and silver
            </div>
          </div>
        </div>
        {renderImage()}
        {renderViewAll()}
        {renderSilver()}
        {renderSilverBanner()}
      </div>
    );
  };
  //render gold image
  const renderImage = () => {
    return (
      <div className="gold_image flt">
        <div className="container-main">
          <div className="row">
            <div className="col-sm-4">
              <div className="gold_collect_cont flt">
                <div
                  className="display_gold display_gold_ani"
                  onClick={() => getGoldDetails("Gold", "Necklace")}
                >
                  <img src={necklace} alt="no_image" />
                </div>
                <div className="gold_name flt">Gold Necklace</div>
              </div>
            </div>
            <div
              className="col-sm-4"
              onClick={() => getGoldDetails("Gold", "Ear Ring")}
            >
              <div className="gold_collect_cont flt">
                <div className="display_gold display_gold_ani">
                  <img src={earings} alt="no_image" />
                </div>
                <div className="gold_name flt">Gold Earrings</div>
              </div>
            </div>
            <div className="col-sm-4">
              <div className="gold_collect_cont flt">
                <div
                  className="display_gold display_gold_ani"
                  onClick={() => getGoldDetails("Gold", "Bracelet")}
                >
                  <img src={bracelet} alt="no_image" />
                </div>
                <div className="gold_name flt">Gold Bracelet</div>
              </div>
            </div>
            <div className="col-sm-4">
              <div className="gold_collect_cont flt">
                <div
                  className="display_gold display_gold_ani"
                  onClick={() => getGoldDetails("Gold", "Bangles")}
                >
                  <img src={bang} alt="no_image" />
                </div>
                <div className="gold_name flt">Gold Bangle</div>
              </div>
            </div>
            <div className="col-sm-4">
              <div className="gold_collect_cont flt">
                <div
                  className="display_gold display_gold_ani"
                  onClick={() => getGoldDetails("Gold", "Finger Ring")}
                >
                  <img src={rings} alt="no_image" />
                </div>
                <div className="gold_name flt">Gold Finger Rings</div>
              </div>
            </div>
            <div className="col-sm-4">
              <div className="gold_collect_cont flt">
                <div
                  className="display_gold display_gold_ani"
                  onClick={() => getGoldDetails("Gold", "Chain")}
                >
                  <img src={neck} alt="no_image" />
                </div>
                <div className="gold_name flt">Gold Chain</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };
  const renderViewAll = () => {
    return (
      <div className="viewall_main flt">
        <div className="view_banner flt">
          <img
            onClick={() => getNewId("diamond")}
            src={banner1}
            alt="no_image"
          />
        </div>
      </div>
    );
  };
  //render silver section
  const renderSilver = () => {
    return (
      <div className="gold_main flt">
        <div className="container-main">
          <div className="gold_heading flt">
            <span>Latest Collection</span>
            <div className="gold_silver flt">
              Your priceless dreams shall glisten like the abiding glint of gold
              looting all the reverent attentions.
            </div>
          </div>
        </div>
      </div>
    );
  };
  const renderSilverBanner = () => {
    return (
      <div className="gold_image flt">
        <div className="container-main">
          <div className="display_gold_cont">
            <div className="gold_display">
              <div
                className="display_gold flt gold_box"
                onClick={() => getGoldDetails("Gold", "Finger Ring")}
              >
                <img src={display1} alt="no_image" />
              </div>
              <div
                className="display_gold flt"
                onClick={() => getGoldDetails("Gold", "Finger Ring")}
              >
                <img src={display2} alt="no_image" />
                <div className="gold_button">
                  {" "}
                  <button className="gold_btn">
                    Rings
                    <span>
                      <img src={arrow} alt="" />
                    </span>
                  </button>
                </div>
              </div>
            </div>
            <div className="gold_display1">
              <div
                className="display_gold flt"
                onClick={() => getGoldDetails("Gold", "Pendant")}
              >
                <img src={display3} alt="no_image" />
                <div className="gold_button">
                  <button className="gold_btn">
                    Pendants
                    <span>
                      <img src={arrow} alt="" />
                    </span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };
  //render platinum section
  const renderRing = () => {
    return (
      <div className="ring_main flt">
        <div className="container-main">
          <div className="ring_cont flt">
            <div className="row">
              <div className="col-sm-6">
                <div className="ring_img flt">
                  <div className="ring_box_img flt">
                    <img src={white_ring} alt="no_img" />
                  </div>
                </div>
              </div>
              <div className="col-sm-6">
                <div className="ring_box flt">
                  <div className="ring_box_text flt">
                    <div className="main_text flt">Prakash Jewellers</div>
                    <div className="sub_text flt">
                      Welcome to Prakash Jewellers, a legacy of excellence in
                      the world of exquisite jewellery. Founded in 1963, our
                      family-owned business has been proudly serving the
                      discerning customers of Karkala town in Udupi District,
                      Karnataka state. As a Third-generation enterprise, we have
                      upheld our commitment to delivering the finest
                      craftsmanship, exceptional quality, and exceptional
                      customer service. At Prakash Jewellers, we understand that
                      jewellery holds a special place in people's hearts. We
                      strive to create timeless pieces that capture the essence
                      of beauty and elegance, crafted with utmost precision and
                      attention to detail. Our artisans, with their extensive
                      expertise and passion for their craft, bring dreams to
                      life, transforming precious metals and gemstones into
                      stunning works of art. One of the cornerstones of our
                      success is the unwavering trust our customers place in us.
                    </div>
                    <a href="/about">
                      {" "}
                      <button className="read_btn">
                        Read More
                        <span>
                          <img src={brown} alt="" />
                        </span>
                      </button>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };
  const renderGoldTabs = () => {
    return (
      <div className="gold_tab_cont flt">
        <div className="container-main">
          <div className="gold_tabs flt">
            <div className="row">
              <div className="col-sm-3">
                <div className="tab_content">
                  <div className="tab_img ">
                    <img src={icon1} alt="" />
                  </div>
                  <div className="tab_head flt">Secured Insured Shipping</div>
                  <div className="tab_text flt">
                    Free shipping for orders above rs. 30000 BIS Certified
                    Jewellery
                  </div>
                </div>
              </div>

              <div className="col-sm-3">
                <div className="tab_content">
                  <div className="tab_img ">
                    <img src={icon2} alt="" />
                  </div>
                  <div className="tab_head flt">Support</div>
                  <div className="tab_text flt">
                    Chat with us on whatsApp at 8762117314
                  </div>
                </div>
              </div>

              <div className="col-sm-3">
                <div className="tab_content">
                  <div className="tab_img ">
                    <img src={icon4} alt="" />
                  </div>
                  <div className="tab_head flt">Fair Gold Weight Pricing</div>
                  <div className="tab_text flt">
                    Gold Weight caclulated deducting Stone Weight
                  </div>
                </div>
              </div>
              <div className="col-sm-3">
                <div className="tab_content">
                  <div className="tab_img ">
                    <img src={icon3} alt="" />
                  </div>
                  <div className="tab_head flt">100% Payment Secure</div>
                  <div className="tab_text flt">
                    ISO/IEC 27001:2013 certified
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  //render new arrivals
  const newArrivals = () => {
    return (
      <div className="gold_main flt">
        <div className="container-main">
          <div className="gold_heading flt">
            <span>New Arrivals</span>
            <div className="gold_silver flt">
              Explore PRAKASH JEWELLARY Designs
            </div>
          </div>
          {newArrivalImage()}
        </div>
      </div>
    );
  };
  //render new arrival image
  const newArrivalImage = () => {
    return (
      <div className="gold_image looknew flt">
        <div className="row">
          {/* {menudata && menudata.whatsnew !== null && menudata.whatsnew !== null ? menudata.whatsnew.map((whats_list) => (
            <div className="col-sm-3" key={whats_list?._id} onClick={() => getNewId(whats_list?.title)}>
              <div className="display_gold flt">
                <img src={image_url + whats_list?.images} alt="no_image" />
              </div>
              <div className="gold_name flt">
                {whats_list.title}
              </div>
            </div>)) : null} */}

          <div className="catregory_cont flt">
            <Slider {...catregorySlider}>
              {menudata &&
              menudata.whatsnew !== null &&
              menudata.whatsnew !== null
                ? menudata.whatsnew.map((category_list: any) => (
                    <div className="category_box flt" key={category_list?._id}>
                      <span onClick={() => getNewId(category_list?.title)}>
                        <div className="category_image category_image_ani  flt">
                          <img
                            src={image_url + category_list?.image}
                            alt="noimage"
                          />
                        </div>
                        <div className="category_name flt">
                          {category_list?.title}
                        </div>
                      </span>
                    </div>
                  ))
                : null}
            </Slider>
          </div>
        </div>
      </div>
    );
  };
  const renderCategoryName = () => {
    return (
      <div className="gold_heading flt">
        <span>Categories</span>
      </div>
    );
  };

  const renderCategories = () => {
    return (
      <div className="category_container flt">
        {menudata &&
          menudata?.category &&
          menudata?.category.map((data: any) => (
            <div className="category_box flt">
              <span onClick={() => getMenuId(data?.title)}>
                <div className="category_image category_image_ani  flt">
                  <img src={image_url + data?.image} alt="noimage" />
                </div>
                <div className="category_name flt">{data?.title}</div>
              </span>
            </div>
          ))}
      </div>
    );
  };
  //render footer
  const renderFooter = () => {
    return (
      <div className="main_login flt">
        <FooterScreen />
      </div>
    );
  };

  return (
    <div className="main_container">
      {renderHeaderBar()}
      {renderBanner()}
      {renderGold()}
      {renderRing()}
      {renderGoldTabs()}
      {newArrivals()}
      {renderCategoryName()}
      {renderCategories()}
      {renderFooter()}
    </div>
  );
};

export default Home;
