import FooterScreen from "../../_common/FooterScreen/FooterScreen";
import HeaderScreen from "../../_common/Header/HeaderScreen";
import "./Refund.css";
import useRefund from "./useRefund";
import { withTranslation } from "react-i18next";
import { loader } from "../../../assets/images";
const Refund = (props: any) => {
  const { t } = props;
  const { privacyData, loaderdata } = useRefund();
  console.log(
    privacyData?.data?.data?.["Cancellation/Refund Policies"]?.description
  );
  //render header
  const renderHeaderBar = () => {
    return (
      <div>
        <HeaderScreen />
      </div>
    );
  };
  /**
   *
   * @returns render main screen
   */

  const renderPrivacy = () => {
    return (
      <div className="container-main">
        <div className="privacyPolicy flt">{t("refund")}</div>
        <div>
          {privacyData &&
            privacyData?.data?.data?.["Cancellation/Refund Policies"]
              ?.description && (
              <p
                dangerouslySetInnerHTML={{
                  __html:
                    privacyData?.data?.data?.["Cancellation/Refund Policies"]
                      ?.description,
                }}
              />
            )}
        </div>
      </div>
    );
  };

  //loading image display
  const loadingDisplay = () => {
    return (
      <div className="product_loader flt">
        <img src={loader} alt="populargold" />
      </div>
    );
  };

  //render footer
  const renderFooter = () => {
    return (
      <div className="main_login flt">
        <FooterScreen />
      </div>
    );
  };
  return (
    <>
      {renderHeaderBar()}
      {loaderdata !== "isfull" && loadingDisplay()}
      <div className="privacy flt">{renderPrivacy()}</div>
      {renderFooter()}
    </>
  );
};
export default withTranslation()(Refund);
