import "./Footer.css";
import {
  logo_footer,
  footicon1,
  footicon2,
  footicon3,
} from "../../../assets/images";
import useMenu from "../Menu/useMenu";
import { whatsapp, insta, fb } from "../../../assets/images";
import { withTranslation } from "react-i18next";
const FooterScreen = (props: any) => {
  /**
   * localization implemented for multiple languiage support
   */
  const { t } = props;

  /**
   * useMenu hook for business logics
   */
  const {
    gold,
    silver,
    diamond,
    aboutUs,
    contactUs,
    getMenuId,
    privacy_notice,
    terms_condition,
    cancellation_refund,
    menudata,
  } = useMenu();
  /**
   *
   * @returns render footer screen
   */
  const renderMainFooter = () => {
    return (
      <div className="footer_main flt">
        <div className="container-main">
          <div className="footer_box flt">
            <div className="footer_address">
              <div className="footer_logo">
                <img src={logo_footer} alt="no_img" />
              </div>
              <div className="footer_add">
                <span>
                  <img src={footicon1} alt="" />
                </span>
                Gangamma Towers, Main Road, Karkala, Karnataka 574104
              </div>
              <div className="footer_add">
                <span>
                  <img src={footicon2} alt="" />
                </span>
                8762117314
              </div>
              <div className="footer_email">
                <span>
                  <img src={footicon3} alt="" />
                </span>
                PRAKASHJEWELLERSKARKALA@GMAIL.COM
              </div>
            </div>
            <div className="foot_category">
              <div className="cat_head flt">Categories</div>
              <ul className="cat_list cate_list flt">
                <li onClick={gold}>Gold</li>
                <li onClick={silver}>Silver</li>
                <li onClick={diamond}>Diamond</li>
              </ul>
            </div>

            <div className="whatsapp_icon">
              <a
                href="https://api.whatsapp.com/send?phone=918762117314"
                target="_blank"
              >
                <img
                  className="img-responsive"
                  src={whatsapp}
                  alt="Prakash Jewellars"
                />
              </a>
            </div>

            <div className="imp_links">
              <div className="cat_head flt">important links</div>
              <ul className="cat_list flt">
                <li onClick={aboutUs}>About Us</li>
                <li onClick={contactUs}>Contact Us</li>
                <li onClick={privacy_notice}>Privacy Policy</li>
                <li onClick={terms_condition}>Terms & Conditions</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    );
  };
  const renderCopyright = () => {
    return (
      <div className="copy_right_main flt">
        <div className="container-main">
          <div className="copy_right_cont flt">
            Designed by Chipsy IT services pvt. ltd
            <div className="footer_media">
              <a href="https://www.instagram.com/prakashjewellerskarkala/">
                <span>
                  <img src={insta} alt="" />
                </span>
              </a>
              <a href="https://www.facebook.com/prakashjewellerskarkala">
                <b>
                  <img src={fb} alt="" />
                </b>
              </a>
            </div>
          </div>
        </div>
      </div>
    );
  };
  return (
    <>
      {renderMainFooter()}
      {renderCopyright()}
    </>
  );
};
export default withTranslation()(FooterScreen);
