import FooterScreen from "../../_common/FooterScreen/FooterScreen";
import HeaderScreen from "../../_common/Header/HeaderScreen";
import { useState, useEffect } from "react";
import "./AboutUs.css";
import { about } from "../../../assets/images";
import abtBanner from "../../../assets/images/about-banner.png";
import { withTranslation } from "react-i18next";
import useCountHook from "../../hooks/useCountHook";
const AboutUs = (props: any) => {
  const { wishListCount } = useCountHook();
  const { t } = props;
  //render header
  const renderHeaderBar = () => {
    return (
      <div>
        <HeaderScreen count={wishListCount} />
      </div>
    );
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  //render about us screen
  const renderAbout = () => {
    return (
      <div className="jewcare_container flt">
        <div className="banner_container flt">
          <img src={abtBanner} alt="noimage" className="img-responsive" />
        </div>
        <div className="abt_container flt">
          <div className="container-main">
            {/* <div className="col-sm-5 about_img"> */}
            {/* <img src={about} alt="noimage" /> */}
            {/* </div> */}
            <div className="col-sm-12">
              <div className="about_main flt">
                {/* <span className="about_head flt">{t("about_us")}</span> */}
                <span className="about_body flt">
                  <img src={about} alt="noimage" />
                  {t("abt_1")}
                  <br />
                  <br />

                  {t("abt_2")}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };
  //render footer
  const renderFooter = () => {
    return (
      <div className="main_login flt">
        <FooterScreen />
      </div>
    );
  };
  return (
    <>
      {renderHeaderBar()}
      {renderAbout()}
      {renderFooter()}
    </>
  );
};
export default withTranslation()(AboutUs);
