import useCart from "./useCart";
import "./Cart.css";
import HeaderScreen from "../../_common/Header/HeaderScreen";
import FooterScreen from "../../_common/FooterScreen/FooterScreen";
import { defaultimg, loader, emptyCart } from "../../../assets/images";
import { withTranslation } from "react-i18next";

const Cart = (props: any) => {
  const { cartData, image_url, loaderdata, wishListCount, Checkout, gotoHome } =
    useCart();
  const { t } = props;

  //render header
  const renderHeaderBar = () => {
    return (
      <div>
        <HeaderScreen count={wishListCount} />
      </div>
    );
  };
  //if cart is empty
  const cartEmpty = () => {
    return (
      <div className="cartEmpty flt">
        <img src={emptyCart} alt="no_text" />
        <div className="cartempty_txt flt">Cart is Empty</div>
      </div>
    );
  };
  //render main cart scrren
  const renderCartScreen = () => {
    return (
      <div className="main_cart flt">
        <div className="container-main">
          <div className="breadcrumbs breadcrumbs1  flt">
            <span onClick={gotoHome}>{t("home")}</span> <span>{t("cart")}</span>
          </div>
          <div className="cartData flt">{t("cart")}</div>
          <div className="row">
            <div className="col-sm-5">
              <div className="cart_img flt">
                <img
                  src={
                    cartData?.product?.pro_image?.image
                      ? image_url + cartData?.product?.pro_image?.image
                      : defaultimg
                  }
                  alt="no_image"
                />
              </div>
            </div>

            <div className="col-sm-7">
              <div className="cart_detail_box flt">
                <div className="price_breakup flt">
                  {cartData?.product?.name ? cartData?.product?.name : "NA"}
                </div>
                <ul className="detail_note cart_note flt">
                  <li>{t("price")}</li>
                  <li className="detail_note1">
                    {t("rs")}{" "}
                    {cartData?.product?.c_gross_value
                      ? cartData?.product?.c_gross_value
                      : "0"}
                  </li>
                  <li>{t("weight")}</li>
                  <li className="detail_note1">
                    {cartData?.product?.pro_details?.weight
                      ? cartData?.product?.pro_details?.weight
                      : "0"}{" "}
                    {t("gm")}
                  </li>
                </ul>
              </div>
              <div className="order_sum flt">
                <div className="price_breakup flt">{t("order_summary")}</div>
                <div className="detailed_cart flt">
                  <div className="quantity">
                    <ul className="detail_note cart_note flt">
                      <li>{t("quantity")}</li>
                      <li className="detail_note1">
                        {cartData?.quantity ? cartData?.quantity : "0"}
                      </li>
                      <li>{t("metal")}</li>
                      <li className="detail_note1">
                        {cartData?.product?.pro_details?.metal
                          ? cartData?.product?.pro_details?.metal
                          : "NA"}
                      </li>
                      <li>{t("total")}</li>
                      <li className="detail_note1">
                        {t("rs")}{" "}
                        {cartData?.product?.c_gross_value
                          ? cartData?.product?.c_gross_value
                          : "0"}
                      </li>
                    </ul>
                    <button onClick={Checkout}>
                      {t("proceed_to_checkout")}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };
  //render footer
  const renderFooter = () => {
    return (
      <div className="main_login flt">
        <FooterScreen />
      </div>
    );
  };

  //loading image display
  const loadingDisplay = () => {
    return (
      <div className="cartpg flt">
        <img src={loader} alt="popular gold" />
      </div>
    );
  };
  return (
    <>
      {loaderdata !== "isfull" && loadingDisplay()}
      {renderHeaderBar()}
      {!cartData || (cartData && cartData.length === 0)
        ? cartEmpty()
        : renderCartScreen()}
      {renderFooter()}
    </>
  );
};
export default withTranslation()(Cart);
